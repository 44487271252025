<template>
    <nav aria-label="...">
        <ul class="pagination">
            <li v-if="currentPage === 1" class="page-item disabled">
                <span class="page-link" @click="prevPage">{{ $t('buttons.previous') }}</span>
            </li>
            <li v-else class="page-item" >
                <span class="page-link" @click="prevPage">{{ $t('buttons.previous') }}</span>
            </li>
            <li class="page-item"><a class="page-link">{{ currentPage }}</a></li>
            <li v-if="currentPage === totalPages" class="page-item disabled">
                <a class="page-link" @click="nextPage">{{ $t('buttons.next') }}</a>
            </li>
            <li class="page-item">
                <a class="page-link" @click="nextPage">{{ $t('buttons.next') }}</a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    props: {
        currentPage: Number,
        totalPages: Number,
    },
    methods:{
        async prevPage(){
            this.$emit('prevPage');
        },
        async nextPage(){
            this.$emit('nextPage');
        }
    }
}
</script>