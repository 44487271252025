// i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    // English language configurations
    labels: {
      users: 'Users',
      roles: 'Roles',
      products: 'Products',
      sources: 'Sources',
      prospects: 'Prospects',
      success: 'Success!',
      welcome: 'Welcome',
      login: 'Login',
      loading: 'Loading',
      actions: 'Actions',
      name: 'Name',
      username: 'Username',
      email: 'Email',
      password: 'Password',
      forgotPassword: 'Forgot your password?',
      resetPassword: 'Reset it here',
      description: 'Description',
      minimumAge: 'Minimum Age',
      minimumBalance: 'Minimum Balance',
      canHaveCredit: 'Can Have Credit',
      creditMustNotBeActive: 'Credit Must Not Be Active',
      minimumWeeks: 'Minimum Weeks',
      isLaw73: 'Law 73',
      isActive: 'Active',
      minimumDaysInactive: 'Minimum Days Inactive',
      telephone: 'Telephone',
      curp: 'Curp',
      nss: 'Nss',
      balance: 'Balance',
      quotedWeeks: 'Quoted Weeks',
      discountedWeeks: 'Discounted Weeks',
      noRecordsFound: 'No records found',
      error: 'Error',
      dob: 'Date of Birth',
      createdAt: 'Created At',
      created: 'Created',
      appointment: 'Appointment',
      call: 'Call',
      contract: 'Contract',
      done: 'Done',
      rejected: 'Rejected',
      status: 'Status',
      multiselectPlaceHolder: 'Search or add a tag',
      // Add other labels here
    },
    buttons: {
      submit: 'Submit',
      update: 'Update',
      cancel: 'Cancel',
      edit: 'Edit',
      delete: 'Delete',
      changePassword: 'Change password',
      createNewRole: 'Create new role',
      clearFilter: 'Clear All Filters',
      previous: 'Previous',
      next: 'Next',
      logout: 'Logout',
      filters: 'Filters',
      apply: 'Apply'
      // Add other button labels here
    },
    // Add other text configurations here
  },
  es: {
    // Spanish language configurations
    labels: {
      users: 'Usuarios',
      roles: 'Roles',
      products: 'Productos',
      sources: 'Promotores',
      prospects: 'Prospectos',
      success: 'Exito!',
      welcome: 'Bienvenido',
      login: 'Iniciar Sesion',
      loading: 'Cargando',
      actions: 'Acciones',
      name: 'Nombre',
      username: 'Nombre de usuario',
      email: 'Correo Electronico',
      password: 'Contraseña',
      forgotPassword: '¿Olvidaste tu contraseña?',
      resetPassword: 'Reestablecela aqui',
      description: 'Descripcion',
      minimumAge: 'Edad Minima',
      minimumBalance: 'Balance Minimo',
      canHaveCredit: 'Credito',
      creditMustNotBeActive: 'Credito Activo',
      minimumWeeks: 'Semanas Minimas',
      isLaw73: 'Ley 73',
      isActive: 'Activo',
      minimumDaysInactive: 'Dias inactivos Minimos',
      telephone: 'Telefono',
      curp: 'Curp',
      nss: 'Nss',
      balance: 'Balance',
      quotedWeeks: 'Semanas Cotizadas',
      discountedWeeks: 'Semanas Descontadas',
      noRecordsFound: 'No se encontraron registros',
      error: 'Error',
      dob: 'Fecha de nacimiento',
      createdAt: 'Fecha de creacion',
      created: 'Creado',
      appointment: 'Cita',
      call: 'Llamada',
      contract: 'Contrato',
      done: 'Finalizado',
      rejected: 'Rechazado',
      status: 'Estatus',
      multiselectPlaceHolder: 'Busca o agrega una opcion',
      // Add other labels here
    },
    buttons: {
      submit: 'Enviar',
      update: 'Actualizar',
      cancel: 'Cancelar',
      edit: 'Editar',
      delete: 'Eliminar',
      changePassword: 'Cambiar contraseña',
      createNewRole: 'Crear nuevo rol',
      clearFilter: 'Limpiar Filtros',
      previous: 'Anterior',
      next: 'Siguiente',
      logout: 'Cerrar Sesion',
      filters: 'Filtros',
      apply: 'Aplicar'
      // Add other button labels here
    },
    // Add other text configurations here
  },
  // Add configurations for other languages as needed
};

const i18n = createI18n({
  locale: 'es', // Set the default locale
  messages,
});

export default i18n;
