<template>
    <div class="container-fluid h-100">
      <!-- position-fixed  -->
      <div class="top-5 start-0 mt-3 ms-3 d-none d-lg-block">
        <!-- SVG icon with padding -->
        <LogoSvg class="position-absolute top-5 start-0 mt-3 ms-3"></LogoSvg>
      </div>
      <!-- Different code for mobile screens -->
      <div class="position-absolute top-5 mt-5 start-50 translate-middle d-lg-none">
        <LogoSvg class="mt-5"></LogoSvg>
      </div>
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-md-3 d-flex flex-column position-relative align-items-center justify-content-center">
            <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 text-center pt-0">
                <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1711486170/sucess_icon_vn8f6p.svg" class="img-fluid" alt="Icon">
                </div>
            </div>
            </div>
              
            <p class="text-white fs-3">¡Operación Exitosa!</p>
            <p class="text-white fs-3 text-center">Enhorabuena. Tu trámite ha sido procesado de forma exitosa, estos son los datos de registro.</p>
          
          <div class="" style="height: 160px;"></div>
        </div>
      </div>
      
      
  
      <div class="card position-absolute bottom-0 start-50 translate-middle-x d-block ml-2" style="border-top-left-radius: 40px; border-top-right-radius: 40px; width: 100%; max-width: 500px; background-color: #291CB5;">
        <div class="card-body d-flex flex-column pl-3">
          <label class="fs-10 mb-4 mt-4 ml-3" for="email" style="color: #F5F6F6;width: 70%; ">Nombre: {{name}}</label>
          <label class="fs-10 mb-4 ml-3" for="email" style="color: #F5F6F6;width: 70%; ">ID documento: {{ id }}.</label>
          <label class="fs-10 mb-4 ml-3 text-white">Estatus: <span style="color: #4DFFE7;">Firmado y Completado</span> </label>
          <label class="fs-10 mb-4 ml-3" for="email" style="color: #F5F6F6;width: 70%; ">Tipo de Firma: Firma Autógrafa Digital</label>
          <label class="fs-10 mb-1 ml-3" for="email" style="color: #F5F6F6;width: 70%; ">Fecha de Firma: {{ date }}</label>
        </div>
        <br>
      </div>
    </div>
  </template>
    
    <script>
    import LogoSvg from '@/components/LogoSvg.vue'
    export default {
      components: {
        LogoSvg
      },
      props: {
        isLoading: Boolean,
        name: String,
        date: String,
        id: String
      },
      data() {
        return {
          email: ''
        };
      },
      methods: {
      }
    }
    </script>
    
    <style>
  .form-control::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #4DFFE7;
      opacity: 1; /* Firefox */
      text-align: center; /* Center the placeholder text */
  }
  
  .form-control:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #4DFFE7;
      text-align: center; /* Center the placeholder text */
  }
  
  .form-control::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #4DFFE7;
      text-align: center; /* Center the placeholder text */
  }
  .shadow-label {
    box-shadow: 0 8px 16px rgba(77, 255, 231, 0.4);
  }
  </style>