<!-- UserForm.vue -->
<template>
    <form @submit.prevent="submitForm">
    <label>
        {{ $t('labels.name') }}
        <input type="text" v-model="role.name" required>
    </label>

    <label>
        {{ $t('labels.description') }}
        <input type="text" v-model="role.description" required>
    </label>

    <button :disabled="isLoading" type="submit">
        <span v-if="!isLoading">{{ $t('buttons.submit') }}</span>
        <span v-else>
          <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </span>
      </button>
    </form>
  </template>

  <script>
    
    export default {
        components: {

        },
        props: {
            isLoading: Boolean, // Pass the item data as a prop
        },
        data() {
            return {
                role: {
                    name: '',
                    description: '',
                },
            };
        },
        computed: {
        },
        methods: {
            submitForm() {
                if (this.isLoading) {
                    return; // Prevent multiple submissions while loading
                }
                this.$emit('add-role', this.role);
            },
        },
    };
  </script>
  
  <style scoped>
  /* Add your component styles here */
  </style>
  