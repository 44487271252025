
<template>
    <div class="card bg-light custom-card" >
        <div class="row mt-3">
            <div class="col d-flex justify-content-end">
                <button type="button" class="btn btn-primary custom-margin" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    {{ $t('buttons.filters') }}
                </button>
            </div>
        </div>
        <div class="card-body">
            <span v-if="prospects.length > 0">
                <div class="table-responsive" style="min-height: 105px;">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col" class="col-6">{{ $t('labels.name') }}</th>
                                <th scope="col" class="col-4">{{ $t('labels.actions') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr v-for="prospect in prospects" :key="prospect.id">
                                <td scope="col" class="col-6 align-middle">{{ prospect.name }}</td>
                                <td scope="col" class="col-4">
                                    <div class="row justify-content-sm-center">
                                        <!-- <div class="col-4">
                                            <button class="btn btn-primary" :disabled="isLoading" @click="editUser(prospect)">
                                                <span>{{ $t('buttons.edit') }}</span>

                                            </button>
                                        </div> -->
                                        <div class="col">
                                            <button class="btn btn-danger" :disabled="isLoading" @click="deleteProspect(prospect.id)">
                                                <span v-if="isDeleting && deletingId === prospect.id"><font-awesome-icon :icon="['fas', 'spinner']" spin /></span>
                                                <span v-else>
                                                    {{ $t('buttons.delete') }}
                                                    
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <PaginationComponent @prevPage="prevPage" @nextPage="nextPage" :currentPage="currentPage" :totalPages="totalPages"></PaginationComponent>
                </div>
            </span>
            <span v-else >
                <div class="row align-items-center" style="min-height: 125px;">
                    <div class="col">
                        {{ $t('labels.noRecordsFound') }}
                    </div>
                </div>
            </span>
        </div>
    </div>
    

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">{{ $t('buttons.filters') }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="input-group mb-3">
                        <label class="input-group-text">{{ $t('labels.status') }}:</label>
                        <div class="custom-multiselect-container">
                            <VueMultiselect v-model="selectedStatus" tag-placeholder="Add this as new tag" placeholder="Busca o agrega una opcion" label="name" track-by="code" :options="status" :multiple="true" :taggable="true" @tag="addStatus"></VueMultiselect>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <label class="input-group-text">
                            {{ $t('labels.products') }}:
                        </label>
                        <div v-if="products.length > 0" class="custom-multiselect-container">    
                            <VueMultiselect v-model="selectedProducts" tag-placeholder="Add this as new tag" placeholder="Busca o agrega una opcion" label="name" track-by="code" :options="products" :multiple="true" :taggable="true" @tag="addProducts"></VueMultiselect>            
                        </div>
                        <input v-else type="text" class="form-control disabled" placeholder="No se encontraron registros" />
                    </div>
                    <div class="input-group mb-3">
                        <label class="input-group-text">
                            {{ $t('labels.name') }}:
                        </label>
                        <input class="form-control" type="text" v-model="nameFilter" @input="applyFilters" />
                        <label class="input-group-text">
                            {{ $t('labels.email') }}:
                        </label>
                        <input class="form-control" type="text" v-model="emailFilter" @input="applyFilters" />
                    </div>
                    <div class="input-group mb-3">
                        <label class="input-group-text">
                            {{ $t('labels.sources') }}:
                        </label>
                        <div v-if="sources.length > 0" class="custom-multiselect-container">
                            <VueMultiselect v-model="selectedSourcess" tag-placeholder="Add this as new tag" placeholder="Busca o agrega una opcion" label="name" track-by="code" :options="sources" :multiple="true" :taggable="true" @tag="addSources"></VueMultiselect>            
                        </div>
                        <input v-else type="text" class="form-control disabled" placeholder="No se encontraron registros" />
                    </div>
                    <!-- TODO: add select for users if the current user is ADMIN -->
                    <div class="input-group mb-3">
                        <label class="input-group-text" for="dobstartDate">{{ $t('labels.dob') }}:</label>
                        <input class="form-control" type="date" id="dobstartDate" v-model="dobStartDate">
                        <input class="form-control" type="date" id="endDate" v-model="dobEndDate">
                    </div>
                    <div class="input-group mb-3">
                        <label class="input-group-text">
                            {{ $t('labels.telephone') }}:
                        </label>
                        <input class="form-control" type="text" v-model="telephoneFilter" @input="applyFilters" />
                        <label class="input-group-text">
                            {{ $t('labels.curp') }}:
                        </label>
                        <input class="form-control" type="text" v-model="curpFilter" @input="applyFilters" />
                        <label class="input-group-text">
                            {{ $t('labels.nss') }}:
                        </label>
                        <input class="form-control" type="text" v-model="nssFilter" @input="applyFilters" />
                    </div>
                    <div class="input-group mb-3">
                        <label class="input-group-text">
                            {{ $t('labels.balance') }}:
                        </label>
                        <input class="form-control" type="number" v-model="balanceMinFilter" @input="applyFilters" />
                        <input class="form-control" type="number" v-model="balanceMaxFilter" @input="applyFilters" />
                    </div>
                    <div class="input-group mb-3">
                        <label class="input-group-text">
                            {{ $t('labels.quotedWeeks') }}:
                        </label>
                        <input class="form-control" type="number" v-model="quotedWeeksMinFilter" @input="applyFilters" />
                        <input class="form-control" type="number" v-model="quotedWeeksMaxFilter" @input="applyFilters" />
                        <label class="input-group-text">
                            {{ $t('labels.discountedWeeks') }}:
                        </label>
                        <input class="form-control" type="number" v-model="discountedWeeksMinFilter" @input="applyFilters" />
                        <input class="form-control" type="number" v-model="discountedWeeksMaxFilter" @input="applyFilters" />
                    </div>
                    <div class="input-group mb-3">
                        <label class="input-group-text">
                            {{ $t('labels.isLaw73') }}:
                        </label>
                        <div class="custom-multiselect-container2">
                            <VueMultiselect v-model="isLaw73Filter" tag-placeholder="Add this as new tag" placeholder="Busca o agrega una opcion" label="name" track-by="code" :options="isLaw73Options" :multiple="true" :taggable="true" @tag="addIsLaw73"></VueMultiselect>
                        </div>
                        <label class="input-group-text">
                            {{ $t('labels.canHaveCredit') }}:
                        </label>
                        <div class="custom-multiselect-container2">
                            <VueMultiselect v-model="creditFilter" tag-placeholder="Add this as new tag" placeholder="Busca o agrega una opcion" label="name" track-by="code" :options="creditOptions" :multiple="true" :taggable="true" @tag="addCredit"></VueMultiselect>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <label class="input-group-text">
                            {{ $t('labels.error') }}:
                        </label>
                        <input class="form-control" type="text" v-model="errorFilter" @input="applyFilters" />
                    </div>
                    <div class="input-group mb-3">
                        <label class="input-group-text" for="startDate">{{ $t('labels.createdAt') }}:</label>
                        <input class="form-control" type="date" id="startDate" v-model="startDate">
                        <input class="form-control" type="date" id="endDate" v-model="endDate">
                    </div>
                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('buttons.cancel') }}</button>
                    <button type="button" class="btn btn-primary">{{ $t('buttons.apply') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaginationComponent from '../table/paginationComponent';
import VueMultiselect from 'vue-multiselect'

export default {
    emits: ['delete-prospect'],
    components:{
        PaginationComponent,
        VueMultiselect
    },
    props:{
        isDeleting: Boolean,
        deletingId: String,
        prospects: Array,
        isLoading: Boolean
    },
    data() {
        return {
            selectedStatus: [
            ],
            isLaw73Options: [
                { name: 'NA', code: 'null' },
                { name: 'Si', code: '1' },
                { name: 'No', code: '2' },
            ],
            creditOptions: [
                { name: 'No', code: 'false' },
                { name: 'Activo', code: 'Activo' },
                { name: 'Liquidado', code: 'Liquidado' },
            ],
            status: [
                { name: 'Creado', code: 'vu' },
                { name: 'Cita', code: 'js' },
                { name: 'Llamada', code: 'os' },
                { name: 'Contrato', code: 'a' },
                { name: 'Finalizado', code: 'b' },
                { name: 'Rechazado', code: 'c' },
            ],
            currentPage: 1,
            itemsPerPage: 20,
            sources: [],
            products: [],
            selectedProducts: [],
            selectedSources: [],
            isLaw73Filter: [],
            creditFilter: [],
            nameFilter: '',
            emailFilter: '',
            telephoneFilter: '',
            curpFilter: '',
            nssFilter: '',
            balanceMinFilter: null,
            balanceMaxFilter: null,
            quotedWeeksMinFilter: null,
            quotedWeeksMaxFilter: null,
            discountedWeeksMinFilter: null,
            discountedWeeksMaxFilter: null,
            dobStartDate: '',
            dobEndDate: '',
            errorFilter: '',
            startDate: '',
            endDate: '',
        }
    },
    watch: {
        products: {
            immediate: true, // Run the handler immediately when the component is created
            handler() {
                this.clearFilters();
            },
        },
    },
    computed: {
      
        filteredProspects() {
            let filtered = this.prospects
            // .filter((prospect) => {
            //     const prospectProducts = prospect.products.map(product => product.name);
            //     return this.getSelectedProducts().some(selectedProduct => prospectProducts.includes(selectedProduct));
            // })
            .filter((prospect) => prospect.name.toLowerCase().includes(this.nameFilter.toLowerCase()))
            .filter((prospect) => prospect.email.toLowerCase().includes(this.emailFilter.toLowerCase()));
    
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return filtered.slice(startIndex, endIndex).map(prospect => ({
            ...prospect,
            roles: prospect.products.map(product => product.name).join(', '), // Concatenate roles
            }));
        },
        totalPages() {
            return Math.ceil(
            this.products
                .filter((prospect) => {
                    const prospectProducts = prospect.products.map(product => product.name);
                    return this.getSelectedProducts().some(selectedProduct => prospectProducts.includes(selectedProduct));
                })
                .filter((prospect) => prospect.name.toLowerCase().includes(this.nameFilter.toLowerCase()))
                .filter((prospect) => prospect.email.toLowerCase().includes(this.emailFilter.toLowerCase()))
                .length / this.itemsPerPage
            );
        },
    },
    methods:{
        addTag (newTag) {
        const tag = {
            name: newTag,
            code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
        }
        this.taggingOptions.push(tag)
        this.taggingSelected.push(tag)
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        applyFilters() {
            // Reset to the first page when filters change
            this.currentPage = 1;
        },
        deleteProspect(id) {
            this.$emit('delete-prospect', id);
        },
        clearFilters() {
            this.products.forEach(product => {
                this.selectedProducts[product.name] = true;
            });
            this.nameFilter = '';
            this.emailFilter = '';
            this.currentPage = 1;
        },
        getSelectedProducts() {
            return Object.keys(this.selectedProducts).filter(product => this.selectedProducts[product]);
        },
    }
}
</script>

<style>
.custom-margin {
  margin-right: 20px; /* Adjust the margin as needed */
}
.custom-multiselect-container {
  width: 90%; /* Adjust the width as needed */
}
.custom-multiselect-container {
  width: 40%; /* Adjust the width as needed */
}
</style>