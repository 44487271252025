<template>
    <div class="card text-start bg-light custom-card">
        <div class="card-body">
            <form @submit.prevent="submitForm">
                <div class="mb-3">
                    <label for="sourceName" class="form-label">{{ $t('labels.name') }}</label>
                    <input type="text" class="form-control" id="sourceName" aria-describedby="emailHelp" v-model="this.source.name">
                </div>
                <button :disabled="isLoading" type="submit" class="btn btn-primary">
                    <span v-if="!isLoading">{{ $t('buttons.submit') }}</span>
                    <span v-else>
                    <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                    </span>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isLoading: Boolean, // Pass the item data as a prop
    },
    data() {
        return {
            source: {
                name: '',
            },
        };
    },
    computed: {
    },
    methods: {
        submitForm() {
            if (this.isLoading) {
                return; // Prevent multiple submissions while loading
            }
            this.$emit('add-source', this.source);
        },
    },
}
</script>
