<!-- ProductTable.vue -->
<template>
    <div class="container mt-5">
      <!-- <div class="row"> -->
        <div class="col-md-4">
          <!-- Filter section goes here -->
          <button @click="createProduct" class="btn btn-primary mb-3">Crear Nuevo Producto</button>
          
            <div class="filters">
        
            <label>
                {{ $t('labels.name') }}:
                <input type="text" v-model="nameFilter" @input="applyFilters" />
            </label>
            <label>
                {{ $t('labels.minimumBalance') }}:
                <input type="number" v-model="minimumBalanceMin" @input="applyFilters" placeholder="Min" />
                <input type6="number" v-model="minimumBalanceMax" @input="applyFilters" placeholder="Max" />
            </label>
            <label>
                {{ $t('labels.minimumWeeks') }}:
                <input type="number" v-model="minimumWeeksMin" @input="applyFilters" placeholder="Min" />
                <input type6="number" v-model="minimumWeeksMax" @input="applyFilters" placeholder="Max" />
            </label>

            <label>
                {{ $t('labels.isLaw73') }}:
                <input type="checkbox" v-model="isLaw73" @change="applyFilters" />
            </label>

            <label>
                {{ $t('labels.isActive') }}:
                <input type="checkbox" v-model="isActive" @change="applyFilters" />
            </label>

            <label>
                {{ $t('labels.minimumDaysInactive') }}:
                <input type="number" v-model="minimumDaysInactiveMin" @input="applyFilters" placeholder="Min" />
                <input type="number" v-model="minimumDaysInactiveMax" @input="applyFilters" placeholder="Max" />
            </label>
        
            <button  class="btn btn-primary mb-3" @click="clearFilters">{{ $t('buttons.clearFilter') }}</button>
            </div>
      
        </div>
  
        <div class="col-md-8">
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <!-- Add more columns as needed -->
                  <th>{{ $t('labels.name') }}</th>
                  <th>{{ $t('labels.minimumAge') }}</th>
                  <th>{{ $t('labels.minimumBalance') }}</th>
                  <th>{{ $t('labels.canHaveCredit') }}</th>
                  <th>{{ $t('labels.creditMustNotBeActive') }}</th>
                  <th>{{ $t('labels.minimumWeeks') }}</th>
                  <th>{{ $t('labels.isLaw73') }}</th>
                  <th>{{ $t('labels.isActive') }}</th>
                  <th>{{ $t('labels.minimumDaysInactive') }}</th>
                  <!-- Add more column headers -->
                  <th>{{ $t('labels.actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in filteredProducts" :key="product.id">
                  <!-- Add more columns as needed -->
                  <td>{{ product.name }}</td>
                  <td>{{ product.minimumAge ?? "NA" }}</td>
                  <td>{{ product.minimumBalance ?? "NA" }}</td>
                  <td>{{ product.canHaveCredit ?? "NA" }}</td>
                  <td>{{ product.creditMustNotBeActive ?? "NA" }}</td>
                  <td>{{ product.minimumWeeks ?? "NA" }}</td>
                  <td>{{ product.isLaw73 ?? "NA" }}</td>
                  <td>{{ product.isActive ?? "NA" }}</td>
                  <td>{{ product.minimumDaysInactive ?? "NA" }}</td>
                  <!-- Add more cells as needed -->
                  <td>
                    <button class="btn btn-primary" :disabled="isLoading" @click="editProduct(product)">
                      <span>{{ $t('buttons.edit') }}</span>
                    </button>
                    <button class="btn btn-danger" :disabled="isLoading" @click="deleteProduct(product.id)">
                      <span v-if="isDeleting && deletingId === product.id"><font-awesome-icon :icon="['fas', 'spinner']" spin /></span>
                      <span v-else>{{ $t('buttons.delete') }}</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
  
          <div class="pagination mt-3">
            <div style="margin: 0 5px;">
    
                <button  class="btn btn-primary mb-3"  @click="prevPage" :disabled="currentPage === 1">{{ $t('buttons.previous') }}</button>
            </div>
            <div>
    
                <span>{{ currentPage }}</span>
            </div>
            <div style="margin: 0 5px;">
    
                <button  class="btn btn-primary mb-3"  @click="nextPage" :disabled="currentPage === totalPages">{{ $t('buttons.next') }}</button>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
  </template>
  
  
    
    <script>
    export default {
      name: "ProductTable",
      props: {
        products: Array, // Pass the item data as a prop
        isLoading: Boolean,
        isDeleting: Boolean,
        deletingId: String
      },
      data() {
        const selectedRoles = {};
        return {
            currentPage: 1,
            itemsPerPage: 20,
            selectedRoles,
            nameFilter: '',
            minimumBalanceMin: null,
            minimumBalanceMax: null,
            minimumWeeksMin: null,
            minimumWeeksMax: null,
            isLaw73: null,
            isActive: null,
            minimumDaysInactiveMin: null,
            minimumDaysInactiveMax: null,
        };
      },
      watch: {
        roles: {
          immediate: true, // Run the handler immediately when the component is created
          handler() {
            // this.clearFilters();
          },
        },
      },
      computed: {
        
        filteredProducts() {
            let filtered = this.products
            .filter((product) => product.name.toLowerCase().includes(this.nameFilter.toLowerCase()))
            .filter((product) => this.filterMinMax(product.minimumBalance, this.minimumBalanceMin, this.minimumBalanceMax))
            .filter((product) => this.filterMinMax(product.minimumWeeks, this.minimumWeeksMin, this.minimumWeeksMax))
            .filter((product) => this.filterBoolean(product.isLaw73, this.isLaw73))
            .filter((product) => this.filterBoolean(product.isActive, this.isActive))
            .filter((product) =>
            this.filterMinMax(product.minimumDaysInactive, this.minimumDaysInactiveMin, this.minimumDaysInactiveMax)
            );
    
          const startIndex = (this.currentPage - 1) * this.itemsPerPage;
          const endIndex = startIndex + this.itemsPerPage;
          return filtered.slice(startIndex, endIndex).map(product => ({
            ...product,
          }));
        },
        totalPages() {
          return Math.ceil(
            this.products
            .filter((product) => product.name.toLowerCase().includes(this.nameFilter.toLowerCase()))
            .filter((product) => this.filterMinMax(product.minimumBalance, this.minimumBalanceMin, this.minimumBalanceMax))
            .filter((product) => this.filterMinMax(product.minimumWeeks, this.minimumWeeksMin, this.minimumWeeksMax))
            .filter((product) => this.filterBoolean(product.isLaw73, this.isLaw73))
            .filter((product) => this.filterBoolean(product.isActive, this.isActive))
            .filter((product) =>
            this.filterMinMax(product.minimumDaysInactive, this.minimumDaysInactiveMin, this.minimumDaysInactiveMax))
              .length / this.itemsPerPage
          );
        },
      },
      methods: {
        filterMinMax(value, min, max) {
            if (min !== null && min !== '' && value < min) {
                return false;
            }
            if (max !== null && max !== '' && value > max) {
                
                return false;
            }
            return true;
        },
        filterBoolean(value, filterValue) {
            if(!filterValue && value === null){
                return true;
            }
            if (filterValue !== null && value !== filterValue) {
                return false;
            }
            return true;
        },
        createProduct(){
          this.$router.push('/products/create-product'); // Or use a custom route if defined
        },
        prevPage() {
          if (this.currentPage > 1) {
            this.currentPage--;
          }
        },
        nextPage() {
          if (this.currentPage < this.totalPages) {
            this.currentPage++;
          }
        },
        applyFilters() {
          // Reset to the first page when filters change
          this.currentPage = 1;
        },
        editProduct(product) {
          // Implement edit product logic
          this.$emit('edit-product', product);
        },
        deleteProduct(id) {
          this.$emit('delete-product', id);
        },
        clearFilters() {
            this.nameFilter = '';
            this.minimumBalanceMin = null;
            this.minimumBalanceMax = null;
            this.minimumWeeksMin = null;
            this.minimumWeeksMax = null;
            this.isLaw73 = null;
            this.isActive = null;
            this.minimumDaysInactiveMin = null;
            this.minimumDaysInactiveMax = null;
        },
      },
      
    };
    </script>
    
    <style scoped>
    /* Add your table styles here */
    
    </style>
    