<!-- ProductForm.vue -->
<template>
    <div class="product-form">
      <form @submit.prevent="submitForm">
        <label>
          {{ $t('labels.name') }}:
          <input class="form-control" type="text" v-model="formData.name" required />
        </label>
        <label>
            {{ $t('labels.minimumAge') }}:
            <input class="form-control" type="number" v-model="formData.minimumAge" :disabled="formData.minimumAgeNA" />
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="formData.minimumAgeNA">
                <label class="form-check-label" for="flexCheckDefault">
                    NA
                </label>
            </div>
        </label>
    
        <label>
            {{ $t('labels.minimumBalance') }}:
            <input class="form-control" type="number" v-model="formData.minimumBalance" :disabled="formData.minimumBalanceNA" />
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="formData.minimumBalanceNA" />
                <label class="form-check-label" for="flexCheckDefault">
                    NA
                </label>
            </div>
        </label>
        
  
  
        <label>
            {{ $t('labels.canHaveCredit') }}:
            <select class="form-select" v-model="formData.canHaveCredit">
                <option value="">NA</option>
                <option value="true">True</option>
                <option value="false">False</option>
            </select>
            
        </label>

        <label>
            {{ $t('labels.creditMustNotBeActive') }}:
            <select class="form-select" v-model="formData.creditMustNotBeActive">
                <option value="">NA</option>
                <option value="true">True</option>
                <option value="false">False</option>
            </select>
            
        </label>

        <label>
            {{ $t('labels.isActive') }}:
            <select class="form-select" v-model="formData.isActive">
                <option value="">NA</option>
                <option value="true">True</option>
                <option value="false">False</option>
            </select>
        </label>

        <label>
            {{ $t('labels.minimumWeeks') }}:
            <input class="form-control" type="number" v-model="formData.minimumWeeks" :disabled="formData.minimumWeeksNA" />
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="formData.minimumWeeksNA" />
                <label class="form-check-label" for="flexCheckDefault">
                    NA
                </label>
            </div>
        </label>

        <label>
            {{ $t('labels.isLaw73') }}:
            <select class="form-select" v-model="formData.isLaw73">
                <option value="">NA</option>
                <option value="true">True</option>
                <option value="false">False</option>
            </select>
        </label>

        <label>
            {{ $t('labels.minimumDaysInactive') }}:
            <input class="form-control" type="number" v-model="formData.minimumDaysInactive" :disabled="formData.minimumDaysInactiveNA" />
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="formData.minimumDaysInactiveNA" />
                <label class="form-check-label" for="flexCheckDefault">
                    NA
                </label>
            </div>
        </label>
        <button class="btn btn-primary mb-3" :disabled="isLoading" type="submit">
            <span v-if="!isLoading">Submit</span>
            <span v-else>
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </span>
        </button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        isLoading: Boolean, // Pass the item data as a prop
    },
    data() {
      return {
        formData: {
          name: '',
          minimumAge: null,
          minimumAgeNA: false,
          minimumBalance: null,
          minimumBalanceNA: false,
          canHaveCredit: false,
          creditMustNotBeActive: false,
          isActive: false,
          minimumWeeks: null,
          minimumWeeksNA: false,
          isLaw73: null,
          minimumDaysInactive: null,
          minimumDaysInactiveNA: false,
        },
      };
    },
    methods: {
      submitForm() {
        // Set the values to null if NA checkbox is checked
        if (this.formData.minimumAgeNA) {
          this.formData.minimumAge = null;
        }
  
        if (this.formData.minimumBalanceNA) {
          this.formData.minimumBalance = null;
        }

        if (this.formData.minimumWeeksNA) {
          this.formData.minimumWeeks = null;
        }

        if (this.formData.minimumDaysInactiveNA) {
          this.formData.minimumDaysInactive = null;
        }
  
        if (this.isLoading) {
            return; // Prevent multiple submissions while loading
        }
        this.$emit('add-product', this.formData);
        // this.resetForm();
      },
      resetForm() {
        this.formData = {
          name: '',
          minimumAge: null,
          minimumAgeNA: false,
          minimumBalance: null,
          minimumBalanceNA: false,
          canHaveCredit: false,
          creditMustNotBeActive: false,
          isActive: false,
          minimumWeeks: null,
          minimumWeeksNA: false,
          isLaw73: null,
          minimumDaysInactive: null,
          minimumDaysInactiveNA: false,
        };
      },
    },
  };
  </script>
  
  <style scoped>
  .product-form {
    max-width: 800px; /* Adjust the max-width as needed */
    margin: auto; /* Center the form */
  }
  </style>
  