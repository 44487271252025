import 'bootstrap/dist/css/bootstrap.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import 'vue-multiselect/dist/vue-multiselect.css';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas )

import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store/store';
import i18n from './utils/i18n';

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(bootstrap)
    .use(router)
    .use(i18n)
    .use(store)
    .mount('#app')