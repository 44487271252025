<!-- eslint-disable prettier/prettier -->
<template>
  <nav v-if="isAuthenticated" class="navbar bg-body-tertiary fixed-top">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item">
              <router-link
                to="/users"
                class="nav-link"
                :class="{ active: $route.path === '/users' }"
                aria-current="page"
                >{{ $t("labels.users") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/roles"
                class="nav-link"
                :class="{ active: $route.path === '/roles' }"
                aria-current="page"
                >{{ $t("labels.roles") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/products"
                class="nav-link"
                :class="{ active: $route.path === '/products' }"
                aria-current="page"
                >{{ $t("labels.products") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/sources"
                class="nav-link"
                :class="{ active: $route.path === '/sources' }"
                aria-current="page"
                >{{ $t("labels.sources") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/prospects"
                class="nav-link"
                :class="{ active: $route.path === '/prospects' }"
                aria-current="page"
                >{{ $t("labels.prospects") }}</router-link
              >
            </li>
            <!-- <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </li> -->
          </ul>
          <div class="d-flex mt-3">
            <button
              data-bs-dismiss="offcanvas"
              @click="logout"
              class="btn btn-outline-success"
              type="submit"
            >
              {{ $t("buttons.logout") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <br v-if="isAuthenticated" />
  <br v-if="isAuthenticated" />
  <br v-if="isAuthenticated" />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    logout() {
      // Remove the token from local storage
      localStorage.removeItem("token");

      // Redirect the user to the login page or another destination
      this.$router.push("/"); // Adjust the route as needed
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped>
.navbar-toggler {
  width: 60px; /* Set the width of the hamburger icon */
  height: 50px; /* Set the height of the hamburger icon */
}
</style>
