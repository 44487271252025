<template>
  <div class="container-fluid h-100">
    <!-- position-fixed  -->
    <div class="top-5 start-0 mt-3 ms-3 d-none d-lg-block">
      <!-- SVG icon with padding -->
      <LogoSvg class="position-absolute top-5 start-0 mt-3 ms-3"></LogoSvg>
    </div>
    <!-- Different code for mobile screens -->
    <div class="position-absolute top-5 mt-5 start-50 translate-middle d-lg-none">
      <LogoSvg class="mt-5"></LogoSvg>
    </div>
    
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-md-3 position-relative">
        <div class="d-lg-none" style="height: 120px;"></div>
      </div>
    </div>
    
    <div class="card position-fixed bottom-0 start-50 translate-middle-x" style="border-top-left-radius: 40px; border-top-right-radius: 40px; width: 100%; max-width: 600px; background-color: #291CB5;">
      <div class="card-body d-flex justify-content-center mt-3">
        <div class="input-group mb-3" style="width: 90%;  display: flex; align-items: center; justify-content: center;">
          <ul v-for="element in policyList" :key="element.id" class="text-white" style="font-size: 12px; text-align: center;">
            <li>{{element.policy.name}}  </li>
          </ul>
          <p style="color: #F5F6F6; text-align: center">HAZ CLICK PARA ACEPTAR LOS SIGUIENTES TERMINOS Y CONDICIONES.</p>
          <div style="width: 100%; display: flex; justify-content: center;">
            <label :disabled="isLoading" @click="aceptTerms" class="btn mt-3 shadow-label d-flex justify-content-center align-items-center" style="background-color: #4DFFE7; color: #3E2EF2; width: 30%; height: 50px;">
              <span v-if="!isLoading">ACEPTAR</span>
              <span v-else>
                <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
              </span>
            </label>
          </div>
        </div>
      </div>
    
    </div>
  </div>
</template>
  
  <script>
  import LogoSvg from '@/components/LogoSvg.vue'
  export default {
    components: {
      LogoSvg
    },
    props: {
      isLoading: Boolean,
      policyList: Array
    },
    data() {
      return {
        
      };
    },
    methods: {
      aceptTerms() {
        // Emit an event with the entered email
        this.$emit('acept-terms');
      }
    }
  }
  </script>
  
  <style>
.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #4DFFE7;
    opacity: 1; /* Firefox */
    text-align: center; /* Center the placeholder text */
}

.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #4DFFE7;
    text-align: center; /* Center the placeholder text */
}

.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #4DFFE7;
    text-align: center; /* Center the placeholder text */
}
.shadow-label {
  box-shadow: 0 8px 16px rgba(77, 255, 231, 0.4);
}
</style>