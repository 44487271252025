<template>
    <div class="custom-dropdown" @click.stop>
      <button @click.prevent="toggleDropdown">{{ label }}</button>
      <div v-if="isOpen" class="dropdown-options">
        <label v-for="option in options" :key="option" class="dropdown-option">
            <div style="display: flex;">
                <div>
                    <input type="checkbox" :checked="model[option]" @change="updateModel(option)">
                </div>
                <div>
                    {{ option }}
                </div>
            </div>
        </label>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      label: String,
      options: Array,
      model: Object,
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      updateModel(option) {
        this.$emit('update:model', { ...this.model, [option]: !this.model[option] });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your component styles here */
  .custom-dropdown {
    position: relative;
    margin-bottom: 15px;
  }
  
  button {
    cursor: pointer;
  }
  
  .dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 5px;
  }
  
  .dropdown-option {
    display: block;
  }
  </style>
  