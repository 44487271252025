<template>
  <div style="background-color: #3E2EF2; height: 90vh;">
    <div class="container-fluid h-100">
      <!-- position-fixed  -->
      <div class="top-5 start-0 mt-3 ms-3 d-none d-lg-block">
        <!-- SVG icon with padding -->
        <LogoSvg class="position-absolute top-5 start-0 mt-3 ms-3"></LogoSvg>
    </div>
    <!-- Different code for mobile screens -->
    <div class="position-absolute top-5 mt-5 start-50 translate-middle d-lg-none">
      <LogoSvg class="mt-5"></LogoSvg>
    </div>
    
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-md-3 d-flex flex-column position-relative align-items-center justify-content-center">
        <!-- Icon image with padding -->
        <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1711486167/hand_gie3p6.svg" class="" style="width: 80px; height: 80px;" alt="Icon">
        
        <p class="text-white fs-6">¡Hola {{this.name}}!</p>
        <p class="text-white fs-6 text-center">Te damos la bienvenida al sitio para el proceso de autenticación y aprobación de documentos de AFIE consultores.</p>
        <div class="d-lg-none" style="height: 120px;"></div>
      </div>
    </div>
    <!-- Different code for mobile screens -->
    <div class="card position-absolute bottom-0 start-0 end-0 d-lg-none" style="border-top-left-radius: 40px; border-top-right-radius: 40px; background-color: #291CB5;">
      <div class="card-body d-flex flex-column align-items-center justify-content-center mt-5">
          <label class="fs-10 mb-2 mt-5" for="email" style="color: #F5F6F6;">Ingresa en la barra de navegacion el URL que se envio a tu correo para poder comenzar el proceso</label>
        </div>
      </div>
      
      <!-- Different code for larger screens -->
    <div class="card position-absolute bottom-0 start-50 translate-middle-x d-none d-lg-block" style="border-top-left-radius: 40px; border-top-right-radius: 40px; width: 100%; max-width: 500px; background-color: #291CB5;">
      <div class="card-body d-flex flex-column align-items-center justify-content-center mt-3">
        <label class="fs-10 mt-3 mb-3" for="email" style="color: #F5F6F6;">Ingresa en la barra de navegacion el URL que se envio a tu correo para poder comenzar el proceso</label>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import LogoSvg from '@/components/LogoSvg.vue'
export default {
  components: {
    LogoSvg
  },
  data() {
    return {
      name: '',
      date: '09/91/2022 21:46',
      id: '61da1a0287729d352bf7bef1',
      error: false,
      isLoading: false,
      emailToVerify: '',
      emailVerified: false,
      termsAcepted: false,
      publicKey: null,
      fileName: null,
      documentUrl: null,
      documentLoadingSuccess: false,
      showPictureUploadForm: false,
      showWaitingForValidation: false,
      
      documentSigned: false,
      showValidated: false,
      showVerified: false,
      latitude: null,
      longitude: null
    };
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
