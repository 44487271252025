<template>
    <div class="card bg-light custom-card" >
        <div class="card-body">
            <span v-if="sources.length > 0">
                <div class="table" style="min-height: 105px;">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col" class="col-6">{{ $t('labels.name') }}</th>
                                <th scope="col" class="col-4">{{ $t('labels.actions') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr v-for="source in sources" :key="source.id">
                                <td scope="col" class="col-6 align-middle">{{ source.name }}</td>
                                <td scope="col" class="col-4">
                                    <div class="row justify-content-sm-center">
                                        <!-- <div class="col-4">
                                            <button class="btn btn-primary" :disabled="isLoading" @click="editUser(source)">
                                                <span>{{ $t('buttons.edit') }}</span>

                                            </button>
                                        </div> -->
                                        <div class="col">
                                            <button class="btn btn-danger" :disabled="isLoading" @click="deleteSource(source.id)">
                                                <span v-if="isDeleting && deletingId === source.id"><font-awesome-icon :icon="['fas', 'spinner']" spin /></span>
                                                <span v-else>
                                                    {{ $t('buttons.delete') }}
                                                    
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </span>
            <span v-else >
                <div class="row align-items-center" style="min-height: 125px;">
                    <div class="col">
                        No se encontraron registros
                    </div>
                </div>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        isDeleting: Boolean,
        deletingId: String,
        sources: Array,
        isLoading: Boolean
    },
    data() {
        return {
        }
    },
    methods:{
        deleteSource(id) {
            this.$emit('delete-source', id);
        },
    }
}
</script>