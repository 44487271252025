<!-- UserForm.vue -->
<template>
    <form @submit.prevent="submitForm">
    <label>
        {{ $t('labels.username') }}:
        <input type="text" v-model="user.username" required>
    </label>

    <label>
        {{ $t('labels.email') }}:
        <input type="email" v-model="user.email" required>
    </label>

    <label>
        {{ $t('labels.password') }}:
        <input v-model="user.password" :type="showPassword ? 'text' : 'password'" required>
        <div style="display: flex;">
            <div style="margin-right: 5px;">
                <button type="button" @click="togglePasswordVisibility">{{ showPassword ? 'Hide' : 'Show' }}</button>
            </div>
            <div class="table-button">
                <button type="button" @click="generateRandomPassword">Generate Random Password</button>
            </div>
        </div>
        <div v-if="user.password.length > 0 && !isStrongPassword(user.password)" class="password-error">
            La contraseña debe tener al menos 8 caracteres y debe incluir mayúsculas, minúsculas, un dígito y un carácter especial.
        </div>
    </label>

    <CustomDropdown label="Roles" :options="roleOptions" :model="selectedRoles" @update:model="updateUserRoles" />
    <p v-if="!isAtLeastOneRoleSelected">Please select at least one role.</p>

    <button :disabled="isLoading" type="submit">
        <span v-if="!isLoading">{{ $t('buttons.submit') }}</span>
        <span v-else>
          <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </span>
      </button>
    </form>
  </template>

  <script>
    import CustomDropdown from '../../components/customDropDown.vue'
    
    export default {
        components: {
            CustomDropdown
        },
        props: {
            isLoading: Boolean, // Pass the item data as a prop
        },
        data() {
            const temp = localStorage.getItem('userRoles');
            const userRoles = temp ? JSON.parse(temp) :[];
            const roleOptions = userRoles.map(role => role.name);
            const selectedRoles = {};
            userRoles.map(role => selectedRoles[role.name] = false);
            console.log(selectedRoles)
            return {
                userRoles,
                user: {
                    username: '',
                    email: '',
                    password: '',
                },
                selectedRoles,
                showPassword: false,
                roleOptions
            };
        },
        computed: {
            isAtLeastOneRoleSelected() {
            // Check if at least one role is selected
            return Object.values(this.selectedRoles).some(role => role);
            },
        },
        methods: {
            isStrongPassword(password) {
                // Minimum length of 8 characters
                const minLength = 8;
            
                // Check if the password meets the minimum length requirement
                if (password.length < minLength) {
                    return false;
                }
            
                // Check if the password contains at least one uppercase letter
                if (!/[A-Z]/.test(password)) {
                    return false;
                }
            
                // Check if the password contains at least one lowercase letter
                if (!/[a-z]/.test(password)) {
                    return false;
                }
            
                // Check if the password contains at least one digit
                if (!/\d/.test(password)) {
                    return false;
                }
            
                // Check if the password contains at least one special character
                if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
                    return false;
                }
            
                // All criteria passed, the password is strong
                return true;
            },
            
            togglePasswordVisibility() {
                this.showPassword = !this.showPassword;
            },
            generateRandomPassword() {
                const length = 12; // You can adjust the length as needed
                const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
                const digits = '0123456789';
                const specialCharacters = '!@#$%^&*(),.?":{}|<>';

                const allCharacters = uppercaseLetters + lowercaseLetters + digits + specialCharacters;

                const getRandomChar = (characters) => {
                    const randomIndex = Math.floor(Math.random() * characters.length);
                    return characters.charAt(randomIndex);
                };

                const randomPassword =
                getRandomChar(uppercaseLetters) +
                getRandomChar(lowercaseLetters) +
                getRandomChar(digits) +
                getRandomChar(specialCharacters) +
                // Fill the remaining characters randomly
                Array.from({ length: length - 4 }, () => getRandomChar(allCharacters)).join('');

                this.user.password = randomPassword;
            },
            toggleRoleDropdown() {
                this.showRoleDropdown = !this.showRoleDropdown;
            },
            updateUserRoles(updatedRoles) {
                this.selectedRoles = { ...updatedRoles };
            },
            submitForm() {
                if (this.isLoading) {
                    return; // Prevent multiple submissions while loading
                }
                const selectedIds = [];
                let i = 0;
                for (const role in this.selectedRoles) {
                    if (this.selectedRoles[role]) {
                        selectedIds.push(this.userRoles[i].id);
                    }
                    i++;
                }
                const user = {
                    "username": this.user.username,
                    "email": this.user.email,
                    "password": this.user.password,
                    "roles": selectedIds
                }
                this.$emit('add-user', user);
            },
        },
    };
  </script>
  
  <style scoped>
  /* Add your component styles here */
  
  
  input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  
  .role-dropdown {
        position: relative;
        margin-bottom: 15px;
    }

    .role-options {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        border: 1px solid #ddd;
        padding: 5px;
    }

    .role-options label {
        display: block;
    }

    /* Ensure label and checkbox appear on the same line */
    .role-checkbox {
        display: inline-block;
        margin-right: 10px;
    }
  </style>
  