<template>
    <div class="container-fluid h-100">
        <div class="top-5 start-0 mt-3 ms-3 d-none d-lg-block">
            <!-- SVG icon with padding -->
            <LogoSvg class="position-absolute top-5 start-0 mt-3 ms-3"></LogoSvg>
        </div>
        <!-- Different code for mobile screens -->
        <div class="position-absolute top-0 mt-5 end-0 d-lg-none">
            <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1725984142/logo_poaq5s.svg" style="width: 70px; height: 70px;" alt="Icon">
        </div>
        <div class="row h-100 justify-content-center">
            <div class="col-md-4 d-flex flex-column position-absolute top-5 align-items-center justify-content-center">
            <h2 class="text-white mt-3 text-center">Contesta las preguntas solicitadas</h2>
            <p class="text-white">PASO 1</p>
            </div>
        </div>
        <div class="card position-absolute bottom-0 start-0 end-0 d-lg-none" 
            style="border-top-left-radius: 40px; border-top-right-radius: 40px; background-color: #291CB5; max-height: 70vh; overflow-y: auto;">
            <div class="card-body d-flex flex-column align-items-center justify-content-center mt-5" style="width: 100%; background-color: #291CB5;">
                <form @submit.prevent="handleSubmit" :key="componentKey">
                    <span v-for="element, index in clientSurveys" :key="element.name">
                        <label class="text-white block text-sm font-medium mb-1" for="name">{{ index + 1 }}.-{{ element.surveyMetaData.question }}<span v-if="element.surveyMetaData.required" class="text-red">*</span></label>
                        <input class="form-control text-center mb-4" style="border-color: #4DFFE7; background-color: #291CB5; color: #4DFFE7; height: 100%;"
                            v-if="element.surveyMetaData.type != 'options' && element.surveyMetaData.type != 'float'" 
                            :type="element.surveyMetaData.type" :required="element.surveyMetaData.required" v-model="element.answer">
                        <input class="form-control form-input w-full mb-4" v-else-if="element.surveyMetaData.type == 'float'"
                            type="number" step="0.01" :required="element.surveyMetaData.required" v-model="element.answer">
                        <DropdownFull :ref="'child_' + index" v-else 
                            :init-value="convertOptions(element.surveyMetaData.options).findIndex(c => c.name == element.answer)"
                            :dropdownOptions="convertOptions(element.surveyMetaData.options)" :model="element.answer" @update:model="updateAnwer($event, index)"/>
                    </span>

                    <div class="flex flex-wrap justify-end space-x-2 mt-4">
                        <button class="btn shadow-label" style="background-color: #4DFFE7; color: #3E2EF2; font-weight: bold; width: 70%; height: 60px;">
                            <span v-if="!isLoading">Enviar</span>
                            <span v-else>
                                <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                            </span>
                        </button>
                    </div>

                </form>
            </div>  
        </div>

        <div class="card position-absolute bottom-0 start-50 translate-middle-x d-none d-lg-block" style="border-top-left-radius: 40px; border-top-right-radius: 40px; width: 100%; max-width: 500px; background-color: #291CB5; max-height: 70vh; overflow-y: auto;">
            <div class="card-body d-flex flex-column align-items-center justify-content-center mt-3">
                <div class="input-group mb-3" style="width: 70%; height: auto; max-height: 70%; display: flex; align-items: center;">
                    <form @submit.prevent="handleSubmit" :key="componentKey">
                    <span v-for="element, index in clientSurveys" :key="element.name">
                        <label class="text-white block text-sm font-medium mb-1" for="name">{{ index + 1 }}.-{{ element.surveyMetaData.question }}<span v-if="element.surveyMetaData.required" class="text-red">*</span></label>
                        <input class="form-control text-center" style="border-color: #4DFFE7; background-color: #291CB5; color: #4DFFE7; height: 100%;"
                            v-if="element.surveyMetaData.type != 'options' && element.surveyMetaData.type != 'float'" 
                            :type="element.surveyMetaData.type" v-model="element.answer">
                        <input class="form-input w-full" v-else-if="element.surveyMetaData.type == 'float'"
                            type="number" step="0.01" v-model="element.answer">
                        <DropdownFull :ref="'child_' + index" v-else 
                            :init-value="convertOptions(element.surveyMetaData.options).findIndex(c => c.name == element.answer)"
                            :dropdownOptions="convertOptions(element.surveyMetaData.options)" :model="element.answer" @update:model="updateAnwer($event, index)"/>
                    </span>
                    <div class="flex flex-wrap justify-end space-x-2 mt-4">
                        <button class="btn shadow-label" style="background-color: #4DFFE7; color: #3E2EF2; font-weight: bold; width: 70%; height: 60px;">
                            <span v-if="!isLoading">Enviar</span>
                            <span v-else>
                                <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                            </span>
                        </button>
                    </div>
                
                </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LogoSvg from '@/components/LogoSvg.vue'
    import DropdownFull from '@/components/DropdownFull.vue';
    export default {
        name: 'SurveyForm',
        props: {
            clientSurveys_: Array,
            isLoading: Boolean,
        },
        components:{
            LogoSvg,
            DropdownFull,
        },
        data(){
            return {
                componentKey: 0,
                originalSurveys: JSON.parse(JSON.stringify(this.clientSurveys_)),
                clientSurveys: this.clientSurveys_
            }
        },
        methods: {
            handleSubmit(){
                this.$emit('survey-submited:value', this.clientSurveys);
            },
            onPressed(index){
                this.clientSurveys[index].surveyMetaData.requestDuringSignature = 
                    !this.clientSurveys[index].surveyMetaData.requestDuringSignature;
                this.clientSurveys[index].answer = null
                this.componentKey++
            },
            updateAnwer(newAnswer, index){
                this.clientSurveys[index].answer = newAnswer.name == 'Elegir' ? null : newAnswer.name;
            },
            convertOptions(options){
                const parsedOptions = options == null ? []:JSON.parse(options.replace(/'/g, '"')).map((item, index) => ({
                    id: index + 1,
                    name: item
                }))
                
                parsedOptions.unshift({ id: 0, name: 'Elegir' });
                
                return parsedOptions
            }
        }
    }
</script>