<template>
  <div id="app" class="container-fluid">
    <SideBar />

    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SideBar from "./components/sidebar/sideBar";
// import "@/../assets/styles.css"; // Adjust the import path

export default {
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  components: {
    SideBar,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

</style>
