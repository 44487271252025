<template>
  <div class="relative inline-flex w-full">
    <button
    :disabled="disableInputs"
      ref="trigger"
      style="background-color: #291CB5; color: #4DFFE7; width: 100%; height: 35px; border-radius: 8px; border-color: #4DFFE7;"
      class=""
      aria-label="Select date range"
      aria-haspopup="true"
      @click.prevent="dropdownOpen = !dropdownOpen"
      :aria-expanded="dropdownOpen"
    >
      <span class="flex items-center">
        <span>{{ dropdownOptions[selected][propname] }}</span>
      </span>
      <svg class="shrink-0 ml-1 fill-current text-slate-400" style="color: #4DFFE7;" width="11" height="7" viewBox="0 0 11 7">
        <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" style="fill: #4DFFE7;"/>
      </svg>
    </button>
    <transition
      enter-active-class="transition ease-out duration-100 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="dropdownOpen" class="z-10 absolute w-full max-h-52 overflow-y-auto top-full left-0 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg mt-1">
        <div
        style="background-color: #291CB5; color: #4DFFE7;"
          ref="dropdown"
          class=""
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
        >
          <button
          onmouseover="this.style.backgroundColor = '#3E2EF2'"
          onmouseout="this.style.backgroundColor = '#0d056d';"
          style="background-color: #0d056d; color: #4DFFE7; width: 100%; height: 35px; border-color: #4DFFE7;"
            v-for="(option, index) in dropdownOptions"
            :key="option.id"
            class=""
            :class="index === selected && 'text-indigo-500'"
            @click.prevent="selected = index; dropdownOpen = false; updateModel(option)"
          >
            <span>{{ option[propname] }}</span>
            <svg class="shrink-0 ml-2 fill-current text-indigo-400 position-absolute end-0" style="padding-top: 5; padding-right: 10px;" :class="option.id !== selected && 'invisible'" width="22" height="15" viewBox="0 0 12 9">
              <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" style="fill: #4DFFE7;" />
            </svg>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
  name: 'DropdownFull',
  props: {
    disableInputs: {
        type: Boolean,
        default: function(){
            return false
        }
    },
    propname: {
      type: String,
      default: function(){
        return 'name'
      }
    },
    dropdownOptions: Array,
    initValue: {
      type: Number,
      default: 0
    }
  },
  setup() {

    const dropdownOpen = ref(false)
    const trigger = ref(null)
    const dropdown = ref(null)
    const selected = ref(0)

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return
      dropdownOpen.value = false
    }

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen.value || keyCode !== 27) return
      dropdownOpen.value = false
    }

    onMounted(() => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler)
    })

    return {
      dropdownOpen,
      trigger,
      dropdown,
      selected,
    }
  },
  methods: {
    updateModel(option) {
      this.$emit('update:model', option);
    },
  },
  created() {
    this.selected = this.initValue == -1 ? 0 : this.initValue;
  }
}
</script>

