<!-- UserTable.vue -->
<template>
  <div class="two-column-layout">
    <div class="form-column">
      <button class="btn btn-primary mb-3" @click="createUser">Crear Nuevo Usuario</button>
      <div class="filters">
        Role:
        <label v-for="role in roles" :key="role.id">
          
          <input class="form-check-input" type="checkbox" v-model="selectedRoles[role.name]" @change="applyFilters" /> {{ role.name }}
        </label>
  
        <label>
          {{ $t('labels.name') }}:
          <input class="form-control" type="text" v-model="nameFilter" @input="applyFilters" />
        </label>
  
        <label>
          {{ $t('labels.email') }}:
          <input class="form-control" type="text" v-model="emailFilter" @input="applyFilters" />
        </label>
        <button class="btn btn-primary mb-3" @click="clearFilters">{{ $t('buttons.clearFilter') }}</button>
      </div>
    </div>
    
  
    <div class="table-responsive">
            <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>{{ $t('labels.email') }}</th>
            <th>{{ $t('labels.name') }}</th>
            <th>Role</th>
            <th>{{ $t('labels.actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in filteredUsers" :key="user.id">
            <td>{{ user.email }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.roles }}</td>
            <td style="display: flex;">
              <div class="table-button">
                <button class="btn btn-primary" :disabled="isLoading" @click="editUser(user)">
                  <span>{{ $t('buttons.edit') }}</span>

                </button>
              </div>
              
              <div class="table-button">
                <button class="btn btn-danger" :disabled="isLoading" @click="deleteUser(user.id)">
                  <span v-if="isDeleting && deletingId === user.id"><font-awesome-icon :icon="['fas', 'spinner']" spin /></span>
                  <span v-else>
                    {{ $t('buttons.delete') }}
                    
                  </span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
  
      <div class="pagination" style="display: flex; flex-wrap: wrap;">
        <div style="margin: 0 5px;">

          <button  class="btn btn-primary mb-3"  @click="prevPage" :disabled="currentPage === 1">{{ $t('buttons.previous') }}</button>
        </div>
        <div>

          <span>{{ currentPage }}</span>
        </div>
        <div style="margin: 0 5px;">

          <button  class="btn btn-primary mb-3"  @click="nextPage" :disabled="currentPage === totalPages">{{ $t('buttons.next') }}</button>
        </div>
      </div>
  
      
    </div>
    
    </div>
  </template>
  
  <script>
  export default {
    name: "UserTable",
    props: {
      users: Array, // Pass the item data as a prop
      roles: Array,
      isLoading: Boolean,
      isDeleting: Boolean,
      deletingId: String
    },
    data() {
      const selectedRoles = {};
      return {
        currentPage: 1,
        itemsPerPage: 20,
        selectedRoles,
        nameFilter: '',
        emailFilter: '',
      };
    },
    watch: {
      roles: {
        immediate: true, // Run the handler immediately when the component is created
        handler() {
          this.clearFilters();
        },
      },
    },
    computed: {
      
      filteredUsers() {
        let filtered = this.users
          .filter((user) => {
              const userRoles = user.roles.map(role => role.name);
              return this.getSelectedRoles().some(selectedRole => userRoles.includes(selectedRole));
          })
          .filter((user) => user.username.toLowerCase().includes(this.nameFilter.toLowerCase()))
          .filter((user) => user.email.toLowerCase().includes(this.emailFilter.toLowerCase()));
  
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return filtered.slice(startIndex, endIndex).map(user => ({
          ...user,
          roles: user.roles.map(role => role.name).join(', '), // Concatenate roles
        }));
      },
      totalPages() {
        return Math.ceil(
          this.users
            .filter((user) => this.selectedRoles[user.role])
            .filter((user) => user.name.toLowerCase().includes(this.nameFilter.toLowerCase()))
            .filter((user) => user.email.toLowerCase().includes(this.emailFilter.toLowerCase()))
            .length / this.itemsPerPage
        );
      },
    },
    methods: {
      createUser(){
        this.$router.push('/users/create-user'); // Or use a custom route if defined
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      applyFilters() {
        // Reset to the first page when filters change
        this.currentPage = 1;
      },
      editUser(user) {
        // Implement edit user logic
        this.$emit('edit-user', user);
      },
      deleteUser(id) {
        this.$emit('delete-user', id);
      },
      clearFilters() {
        console.log(this.roles, this.users)
        this.roles.forEach(role => {
          this.selectedRoles[role.name] = true;
        });
        this.nameFilter = '';
        this.emailFilter = '';
        this.currentPage = 1;
      },
      getSelectedRoles() {
        return Object.keys(this.selectedRoles).filter(role => this.selectedRoles[role]);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your table styles here */
  
  </style>
  