import { createRouter, createWebHistory } from 'vue-router'
import { 
  MainPage,
  SignDocumentPageVue } from '../pages';

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage,
  },
  {
    path: '/document/:token',
    name: 'SignDocumentPageVue',
    component: SignDocumentPageVue,
    meta: { requiresAuth: false }
  },
  {
    path: "/:pathMatch(.*)*",
    component: MainPage,
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token');
  localStorage.setItem('isAuthenticated', !!localStorage.getItem('token'),)
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router