<!-- UserTable.vue -->
<template>
    <div class="two-column-layout">
      <div class="form-column">
        <button @click="createRole">{{ $t('buttons.createNewRole') }}</button>
      </div>
      
    
      <div class="user-table">
        <table>
          <thead>
            <tr>
              <th>{{ $t('labels.name') }}</th>
              <th>{{ $t('labels.actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="role in roles" :key="role.id">
              <td>{{ role.name }}</td>
              <td style="display: flex;">
                <div class="table-button">
                  <button :disabled="isLoading" @click="deleteRole(role.id)">
                    <span v-if="isDeleting && deletingId === role.id"><font-awesome-icon :icon="['fas', 'spinner']" spin /></span>
                    <span v-else>
                        {{ $t('buttons.delete') }}
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
    
        <div class="pagination" style="display: flex; flex-wrap: wrap;">
          <div style="margin: 0 5px;">
  
            <button  @click="prevPage" :disabled="currentPage === 1">Anterior</button>
          </div>
          <div>
  
            <span>{{ currentPage }}</span>
          </div>
          <div style="margin: 0 5px;">
  
            <button  @click="nextPage" :disabled="currentPage === totalPages">Siguiente</button>
          </div>
        </div>
    
        
      </div>
      
      </div>
    </template>
    
    <script>
    export default {
      name: "RoleTable",
      props: {
        roles: Array,
        isLoading: Boolean,
        isDeleting: Boolean,
        deletingId: String
      },
      data() {
        return {
          currentPage: 1,
          itemsPerPage: 20,
        };
      },
      watch: {
      },
      computed: {
        totalPages() {
            return Math.ceil(
            this.roles
                .length / this.itemsPerPage
            );
        },
      },
      methods: {
        createRole(){
          this.$router.push('/roles/create-role'); // Or use a custom route if defined
        },
        prevPage() {
          if (this.currentPage > 1) {
            this.currentPage--;
          }
        },
        nextPage() {
          if (this.currentPage < this.totalPages) {
            this.currentPage++;
          }
        },
        deleteRole(id) {
          this.$emit('delete-role', id);
        },
      },
    };
    </script>
    
    <style scoped>
    /* Add your table styles here */
    
    </style>
    